import { Capacitor } from "@capacitor/core";

export function isNativeIOSPlatform() {
  return Capacitor.getPlatform() === "ios";
}

export function isSafariBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isSafari =
    userAgent.includes("safari") && !userAgent.includes("chrome");
  return isSafari;
}
