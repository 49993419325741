import {
  ChevronLeftIcon,
  HamburgerMenuIcon,
  OpenInNewWindowIcon,
  PlusCircledIcon,
} from "@radix-ui/react-icons";
import {
  Box,
  DropdownMenu,
  Flex,
  Heading,
  IconButton,
  Link,
  Separator,
} from "@radix-ui/themes";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useAppLogo from "../../assets/use-app-icon.png";
import { env } from "../../env";
import { routes } from "../../routes";
import { isNativeIOSPlatform } from "../../shared/utils/environment";
import { useCampingStation } from "../hooks/use-camping-station";
import { useCampingStationSlug } from "../hooks/use-camping-station-slug";

export const CampingHeader: React.FC = () => {
  const navigate = useNavigate();
  const campingStation = useCampingStation();
  const isNativeIOS = isNativeIOSPlatform();

  const campingStationSlug = useCampingStationSlug();
  const campingStationHomeRoute = routes.camping.index(campingStationSlug);
  const campingStationRentRoute = routes.camping.rent.index(campingStationSlug);

  return (
    <Box width="100%">
      <Flex direction="row" gap="2" align="center">
        {isNativeIOS && (
          <IconButton
            color="gray"
            variant="ghost"
            onClick={() => {
              void navigate(-1);
            }}
            style={{ flexShrink: "0" }}
          >
            <ChevronLeftIcon width="18" height="18" />
          </IconButton>
        )}
        <RouterLink to={campingStationHomeRoute} style={{ flexShrink: "0" }}>
          <img src={useAppLogo} className="shared-logo-small" alt="Use logo" />
        </RouterLink>

        <Box flexGrow="1" minWidth="0">
          <RouterLink to={campingStationHomeRoute}>
            <Heading as="h1" truncate>
              {campingStation.name}
            </Heading>
          </RouterLink>
        </Box>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton color="gray" variant="outline">
              <HamburgerMenuIcon width="18" height="18" />
            </IconButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {/* We had to use custom styles for the menu items because using 'asChild' produced errors when opening the menu (#BRA-41). */}
            <DropdownMenu.Item className="dropdown-menu-item">
              <RouterLink
                to={campingStationRentRoute}
                className="dropdown-menu-item-content"
              >
                <PlusCircledIcon />
                <span>Neue Buchung</span>
              </RouterLink>
            </DropdownMenu.Item>
            <DropdownMenu.Item className="dropdown-menu-item">
              <Link
                href={env.contactPage}
                target="_blank"
                className="dropdown-menu-item-content"
              >
                <OpenInNewWindowIcon />
                <span>Kontakt</span>
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className="dropdown-menu-item">
              <Link
                href="https://www.usegroup.eu/impressum"
                target="_blank"
                className="dropdown-menu-item-content"
              >
                <OpenInNewWindowIcon />
                <span>Impressum</span>
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className="dropdown-menu-item">
              <Link
                href="https://www.usegroup.eu/datenschutz"
                target="_blank"
                className="dropdown-menu-item-content"
              >
                <OpenInNewWindowIcon />
                <span>Datenschutz</span>
              </Link>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>

      <Box mt="2">
        <Separator size="4" />
      </Box>
    </Box>
  );
};
